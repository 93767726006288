.gl-box--very-urgent p {
  color: #fff;
}

.gl-callout--very-urgent.full-width {
  justify-content: center;
  align-items: center;
  height: 90vh;
  max-width: unset;
  text-align: center;
  display: flex;
}


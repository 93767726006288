.inner-content {
  max-width: 960px;
  margin: 0 auto;
}

.opt-out-block {
  margin-bottom: 40px;
}

.info-text {
  margin-top: 2em;
}

@media (min-width: 600px) {
  [dir="ltr"] .page-opt-out .left {
    padding-right: 100px;
    border-right: 1px solid #d9dcdd;
  }

  [dir="ltr"] .page-opt-out .right {
    padding-left: 100px;
  }

  [dir="rtl"] .page-opt-out .left {
    padding-left: 100px;
    border-left: 1px solid #d9dcdd;
  }

  [dir="rtl"] .page-opt-out .right {
    padding-right: 100px;
  }
}

.cstudio-component {
  position: relative;
  min-height: 250px;
}

.cstudio-component-landing {
  position: relative;
}

.cstudio-component .newsletter-overlay-page {
  min-height: 350px;
  padding-right: 260px;
}

.cstudio-component-toggler {
  margin-left: 10px;
}

.cstudio-component-collapsible {
  display: none;
}

.cstudio-component-collapsible.is-toggled {
  display: block;
}

.cstudio-component-collapsible-content {
  display: block;
  padding-top: 10px;
  padding-left: 38px;
}

.cstudio-component-collapsible ul {
  list-style: inside square;
  margin: 16px 0;
}

.cstudio-form-elements > .row {
  margin-right: -15px;
  margin-left: -15px;
}

.cstudio-section.newsletter-overlay-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 230px;
  height: 100%;
}

.hero {
  height: 400px;
  margin-bottom: 40px;
  background-color: #ccc;
  background-position: center;
  background-size: cover;
}

.hero .inner-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opt-out-title {
  max-width: 500px;
  color: #fff;
}

.substitude-header {
  text-align: center;
  background-color: #000;
}

.substitude-header__link {
  fill: #fff;
}

.substitude-header__link-rbk img {
  width: 200px;
  padding: 22px;
}

@media (max-width: 600px) {
  .hero {
    height: 240px;
  }
}

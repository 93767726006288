.opt-out-block-yzy {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: unset;
    text-align: center;
}

.gl-cta.gl-cta--primary.button-yzy {
    width: auto;
}

.gl-app {
    display: grid;
    justify-content: center;
}

.gl-app .sidebar {
    grid-column-start: 1;
    grid-row-start: 3;
    grid-row-end: 4;
}

.gl-app .item-header {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 3;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
   .gl-app .item-header.hidden {
        display: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .gl-app  .item-header.hidden {
        display: none;
    }
}


.sidebar {
    position: fixed;
    top: 0;
    width: 170px;
    padding: 20px;
}

.sidebar li {
    margin-bottom: 15px;
}

.sidebar .navigation-bottom {
    position: fixed;
    bottom: 15px;
}

.sidebar .opt-out-menu {
  display: none;
}

.sidebar .opt-out-close-menu {
  line-height: 50px;
  width: 100%;
  position: fixed;
  top: inherit; 
}
ul > li:first-child {
  position: fixed;
  top: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .sidebar {
    padding-top: 17.5px;
  }
  .sidebar .navigation {
    display: none;
  }
  .sidebar .opt-out-menu, .sidebar .navigation.shown {
    display: initial;
  }
  ul > li:first-child {
    position: fixed;
    top: 70px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .sidebar {
    padding-top: 17.5px;
  }
  .sidebar .navigation {
    display: none;
  }
  .sidebar .opt-out-menu, .sidebar .navigation.shown {
    display: initial;
  }
  ul > li:first-child {
    position: fixed;
    top: 70px;
  }
}

@media screen and (min-width: 1600px) {
  .sidebar .opt-out-close-menu {
    display: none;
  }
}
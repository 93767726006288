.gl-box {
  margin-bottom: 40px;
}

.gl-box__content {
  padding: 30px 20px;
}
.full-width .back-to-home {
  margin-top: 15px;
  width: auto;
}

.item-header .row {
    justify-content: center;
    align-items: center;
    height: 90vh;
    max-width: unset;
    text-align: center;
}

@media only screen and (min-width: 600px) {
  .gl-box {
    width: 100%;
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 960px) {
  .alert-msg {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 959px) {
  .alert-msg {
    margin-top: 20px;
  }
}

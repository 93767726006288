.flyout-content-in ul li {
  line-height: 1;
  font-size: 13px;
}

.header_promo_slot .htmlslotcontainer p {
  margin-bottom: 0;
}

.new-header .logo {
  width: 166px;
}

.simplesearchinput.textinput {
  height: 19px !important;
  line-height: normal !important;
}

.simplesearchinput.textinput:focus {
  box-shadow: none !important;
}

.simplesearchinput.textinput::placeholder {
  text-transform: none !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  opacity: .6 !important;
}

.footer_navigation h5 {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
}

.footer_navigation ul, .footer_copy ul {
  line-height: inherit;
}

.footer_copy {
  width: 980px;
}

.footer-copy li {
  font-size: 14px;
}

.footer-copy h5 {
  margin-bottom: 10px;
}

.gl-cta {
  height: auto;
}

.gl-cta:hover {
  text-decoration: none;
}

.gl-cta--tertiary::after {
  content: none;
}

.gl-cta--tertiary::before {
  border-color: inherit;
}

.button-primary, button, button span, .bp-custom, .bp-custom:after, .bp-custom span, .button-ctn span, .button-atb, .button-atb span, .primary-label {
  font-family: inherit;
  font-size: inherit;
  text-transform: uppercase;
  position: relative;
}

[dir="rtl"] .gl-cta__icon {
  margin-right: 20px;
  margin-left: 0;
  transform: rotate(180deg);
}

[dir="rtl"] .gl-cta--primary:not(.gl-cta--disabled):focus .gl-cta__icon, [dir="rtl"] .gl-cta--primary:not(.gl-cta--disabled):hover .gl-cta__icon, [dir="rtl"] .gl-cta--primary-light:not(.gl-cta--disabled):focus .gl-cta__icon, [dir="rtl"] .gl-cta--primary-light:not(.gl-cta--disabled):hover .gl-cta__icon {
  transform: rotate(180deg) translateX(5px);
}

@media (max-width: 600px) {
  [class*="col-s-"] {
    width: 100% !important;
  }
  html {
    font-size: 62.5% !important;
  }
  .simplesearchinput.textinput {
    height: 30px !important;
  }
}
